import { TableColumn } from '@/classes'

const Columns = [
  new TableColumn('Inv No.', 'invoiceRef', true, 'desc', 'left'),
  new TableColumn('Creditor Name', 'creditorName', true, 'desc', 'left'),
  new TableColumn('Inv Date', 'invoiceDate', true, 'asc', 'left'),
  new TableColumn('Date Due', 'due', true, 'desc', 'left'),
  new TableColumn('Balance', 'balance', true, 'asc', 'right'),
  new TableColumn('Inv.Incl GST', 'totalAmount', true, 'asc', 'right'),
  new TableColumn('GST', 'gstAmount', true, 'asc', 'right'),
  new TableColumn('Inv Amount', 'amount', true, 'asc', 'right'),
  new TableColumn('PO Number', 'purchaseOrderNo', true, 'asc', 'left'),
  new TableColumn('Quote No', 'quoteNo', true, 'asc', 'left'),
  new TableColumn('ABN', 'abn', true, 'asc', 'centered'),
  new TableColumn('Exp', 'exp', true, 'asc', 'centered'),
  new TableColumn('', 'view', true, 'desc', 'centered')
]

export { Columns }