/*undefined*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.CreditorListFilter = (function() {

    /**
     * Namespace CreditorListFilter.
     * @exports CreditorListFilter
     * @namespace
     */
    var CreditorListFilter = {};

    CreditorListFilter.CreditorListFilterModel = (function() {

        /**
         * Properties of a CreditorListFilterModel.
         * @memberof CreditorListFilter
         * @interface ICreditorListFilterModel
         * @property {string|null} [poNo] CreditorListFilterModel poNo
         * @property {string|null} [creditorName] CreditorListFilterModel creditorName
         * @property {string|null} [quoteNo] CreditorListFilterModel quoteNo
         * @property {number|null} [filterType] CreditorListFilterModel filterType
         * @property {string|null} [startDate] CreditorListFilterModel startDate
         * @property {string|null} [endDate] CreditorListFilterModel endDate
         * @property {string|null} [invoiceNo] CreditorListFilterModel invoiceNo
         * @property {string|null} [sortColumn] CreditorListFilterModel sortColumn
         * @property {string|null} [sortOrder] CreditorListFilterModel sortOrder
         * @property {number|null} [pageIndex] CreditorListFilterModel pageIndex
         * @property {number|null} [pageSize] CreditorListFilterModel pageSize
         * @property {string|null} [exported] CreditorListFilterModel exported
         */

        /**
         * Constructs a new CreditorListFilterModel.
         * @memberof CreditorListFilter
         * @classdesc Represents a CreditorListFilterModel.
         * @implements ICreditorListFilterModel
         * @constructor
         * @param {CreditorListFilter.ICreditorListFilterModel=} [p] Properties to set
         */
        function CreditorListFilterModel(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * CreditorListFilterModel poNo.
         * @member {string|null|undefined} poNo
         * @memberof CreditorListFilter.CreditorListFilterModel
         * @instance
         */
        CreditorListFilterModel.prototype.poNo = null;

        /**
         * CreditorListFilterModel creditorName.
         * @member {string|null|undefined} creditorName
         * @memberof CreditorListFilter.CreditorListFilterModel
         * @instance
         */
        CreditorListFilterModel.prototype.creditorName = null;

        /**
         * CreditorListFilterModel quoteNo.
         * @member {string|null|undefined} quoteNo
         * @memberof CreditorListFilter.CreditorListFilterModel
         * @instance
         */
        CreditorListFilterModel.prototype.quoteNo = null;

        /**
         * CreditorListFilterModel filterType.
         * @member {number|null|undefined} filterType
         * @memberof CreditorListFilter.CreditorListFilterModel
         * @instance
         */
        CreditorListFilterModel.prototype.filterType = null;

        /**
         * CreditorListFilterModel startDate.
         * @member {string|null|undefined} startDate
         * @memberof CreditorListFilter.CreditorListFilterModel
         * @instance
         */
        CreditorListFilterModel.prototype.startDate = null;

        /**
         * CreditorListFilterModel endDate.
         * @member {string|null|undefined} endDate
         * @memberof CreditorListFilter.CreditorListFilterModel
         * @instance
         */
        CreditorListFilterModel.prototype.endDate = null;

        /**
         * CreditorListFilterModel invoiceNo.
         * @member {string|null|undefined} invoiceNo
         * @memberof CreditorListFilter.CreditorListFilterModel
         * @instance
         */
        CreditorListFilterModel.prototype.invoiceNo = null;

        /**
         * CreditorListFilterModel sortColumn.
         * @member {string|null|undefined} sortColumn
         * @memberof CreditorListFilter.CreditorListFilterModel
         * @instance
         */
        CreditorListFilterModel.prototype.sortColumn = null;

        /**
         * CreditorListFilterModel sortOrder.
         * @member {string|null|undefined} sortOrder
         * @memberof CreditorListFilter.CreditorListFilterModel
         * @instance
         */
        CreditorListFilterModel.prototype.sortOrder = null;

        /**
         * CreditorListFilterModel pageIndex.
         * @member {number|null|undefined} pageIndex
         * @memberof CreditorListFilter.CreditorListFilterModel
         * @instance
         */
        CreditorListFilterModel.prototype.pageIndex = null;

        /**
         * CreditorListFilterModel pageSize.
         * @member {number|null|undefined} pageSize
         * @memberof CreditorListFilter.CreditorListFilterModel
         * @instance
         */
        CreditorListFilterModel.prototype.pageSize = null;

        /**
         * CreditorListFilterModel exported.
         * @member {string|null|undefined} exported
         * @memberof CreditorListFilter.CreditorListFilterModel
         * @instance
         */
        CreditorListFilterModel.prototype.exported = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * CreditorListFilterModel _poNo.
         * @member {"poNo"|undefined} _poNo
         * @memberof CreditorListFilter.CreditorListFilterModel
         * @instance
         */
        Object.defineProperty(CreditorListFilterModel.prototype, "_poNo", {
            get: $util.oneOfGetter($oneOfFields = ["poNo"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * CreditorListFilterModel _creditorName.
         * @member {"creditorName"|undefined} _creditorName
         * @memberof CreditorListFilter.CreditorListFilterModel
         * @instance
         */
        Object.defineProperty(CreditorListFilterModel.prototype, "_creditorName", {
            get: $util.oneOfGetter($oneOfFields = ["creditorName"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * CreditorListFilterModel _quoteNo.
         * @member {"quoteNo"|undefined} _quoteNo
         * @memberof CreditorListFilter.CreditorListFilterModel
         * @instance
         */
        Object.defineProperty(CreditorListFilterModel.prototype, "_quoteNo", {
            get: $util.oneOfGetter($oneOfFields = ["quoteNo"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * CreditorListFilterModel _filterType.
         * @member {"filterType"|undefined} _filterType
         * @memberof CreditorListFilter.CreditorListFilterModel
         * @instance
         */
        Object.defineProperty(CreditorListFilterModel.prototype, "_filterType", {
            get: $util.oneOfGetter($oneOfFields = ["filterType"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * CreditorListFilterModel _startDate.
         * @member {"startDate"|undefined} _startDate
         * @memberof CreditorListFilter.CreditorListFilterModel
         * @instance
         */
        Object.defineProperty(CreditorListFilterModel.prototype, "_startDate", {
            get: $util.oneOfGetter($oneOfFields = ["startDate"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * CreditorListFilterModel _endDate.
         * @member {"endDate"|undefined} _endDate
         * @memberof CreditorListFilter.CreditorListFilterModel
         * @instance
         */
        Object.defineProperty(CreditorListFilterModel.prototype, "_endDate", {
            get: $util.oneOfGetter($oneOfFields = ["endDate"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * CreditorListFilterModel _invoiceNo.
         * @member {"invoiceNo"|undefined} _invoiceNo
         * @memberof CreditorListFilter.CreditorListFilterModel
         * @instance
         */
        Object.defineProperty(CreditorListFilterModel.prototype, "_invoiceNo", {
            get: $util.oneOfGetter($oneOfFields = ["invoiceNo"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * CreditorListFilterModel _sortColumn.
         * @member {"sortColumn"|undefined} _sortColumn
         * @memberof CreditorListFilter.CreditorListFilterModel
         * @instance
         */
        Object.defineProperty(CreditorListFilterModel.prototype, "_sortColumn", {
            get: $util.oneOfGetter($oneOfFields = ["sortColumn"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * CreditorListFilterModel _sortOrder.
         * @member {"sortOrder"|undefined} _sortOrder
         * @memberof CreditorListFilter.CreditorListFilterModel
         * @instance
         */
        Object.defineProperty(CreditorListFilterModel.prototype, "_sortOrder", {
            get: $util.oneOfGetter($oneOfFields = ["sortOrder"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * CreditorListFilterModel _pageIndex.
         * @member {"pageIndex"|undefined} _pageIndex
         * @memberof CreditorListFilter.CreditorListFilterModel
         * @instance
         */
        Object.defineProperty(CreditorListFilterModel.prototype, "_pageIndex", {
            get: $util.oneOfGetter($oneOfFields = ["pageIndex"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * CreditorListFilterModel _pageSize.
         * @member {"pageSize"|undefined} _pageSize
         * @memberof CreditorListFilter.CreditorListFilterModel
         * @instance
         */
        Object.defineProperty(CreditorListFilterModel.prototype, "_pageSize", {
            get: $util.oneOfGetter($oneOfFields = ["pageSize"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * CreditorListFilterModel _exported.
         * @member {"exported"|undefined} _exported
         * @memberof CreditorListFilter.CreditorListFilterModel
         * @instance
         */
        Object.defineProperty(CreditorListFilterModel.prototype, "_exported", {
            get: $util.oneOfGetter($oneOfFields = ["exported"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Encodes the specified CreditorListFilterModel message. Does not implicitly {@link CreditorListFilter.CreditorListFilterModel.verify|verify} messages.
         * @function encode
         * @memberof CreditorListFilter.CreditorListFilterModel
         * @static
         * @param {CreditorListFilter.ICreditorListFilterModel} m CreditorListFilterModel message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreditorListFilterModel.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.poNo != null && Object.hasOwnProperty.call(m, "poNo"))
                w.uint32(10).string(m.poNo);
            if (m.creditorName != null && Object.hasOwnProperty.call(m, "creditorName"))
                w.uint32(18).string(m.creditorName);
            if (m.quoteNo != null && Object.hasOwnProperty.call(m, "quoteNo"))
                w.uint32(26).string(m.quoteNo);
            if (m.filterType != null && Object.hasOwnProperty.call(m, "filterType"))
                w.uint32(32).int32(m.filterType);
            if (m.startDate != null && Object.hasOwnProperty.call(m, "startDate"))
                w.uint32(42).string(m.startDate);
            if (m.endDate != null && Object.hasOwnProperty.call(m, "endDate"))
                w.uint32(50).string(m.endDate);
            if (m.invoiceNo != null && Object.hasOwnProperty.call(m, "invoiceNo"))
                w.uint32(58).string(m.invoiceNo);
            if (m.sortColumn != null && Object.hasOwnProperty.call(m, "sortColumn"))
                w.uint32(66).string(m.sortColumn);
            if (m.sortOrder != null && Object.hasOwnProperty.call(m, "sortOrder"))
                w.uint32(74).string(m.sortOrder);
            if (m.pageIndex != null && Object.hasOwnProperty.call(m, "pageIndex"))
                w.uint32(80).int32(m.pageIndex);
            if (m.pageSize != null && Object.hasOwnProperty.call(m, "pageSize"))
                w.uint32(88).int32(m.pageSize);
            if (m.exported != null && Object.hasOwnProperty.call(m, "exported"))
                w.uint32(98).string(m.exported);
            return w;
        };

        /**
         * Encodes the specified CreditorListFilterModel message, length delimited. Does not implicitly {@link CreditorListFilter.CreditorListFilterModel.verify|verify} messages.
         * @function encodeDelimited
         * @memberof CreditorListFilter.CreditorListFilterModel
         * @static
         * @param {CreditorListFilter.ICreditorListFilterModel} message CreditorListFilterModel message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreditorListFilterModel.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CreditorListFilterModel message from the specified reader or buffer.
         * @function decode
         * @memberof CreditorListFilter.CreditorListFilterModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {CreditorListFilter.CreditorListFilterModel} CreditorListFilterModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreditorListFilterModel.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.CreditorListFilter.CreditorListFilterModel();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.poNo = r.string();
                    break;
                case 2:
                    m.creditorName = r.string();
                    break;
                case 3:
                    m.quoteNo = r.string();
                    break;
                case 4:
                    m.filterType = r.int32();
                    break;
                case 5:
                    m.startDate = r.string();
                    break;
                case 6:
                    m.endDate = r.string();
                    break;
                case 7:
                    m.invoiceNo = r.string();
                    break;
                case 8:
                    m.sortColumn = r.string();
                    break;
                case 9:
                    m.sortOrder = r.string();
                    break;
                case 10:
                    m.pageIndex = r.int32();
                    break;
                case 11:
                    m.pageSize = r.int32();
                    break;
                case 12:
                    m.exported = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Decodes a CreditorListFilterModel message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof CreditorListFilter.CreditorListFilterModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {CreditorListFilter.CreditorListFilterModel} CreditorListFilterModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreditorListFilterModel.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        return CreditorListFilterModel;
    })();

    return CreditorListFilter;
})();

module.exports = $root;
