<template>
  <base-modal-ex :active.sync="isActive"
    :animation-in="animationIn"
    :animation-out="animationOut"
    :animation-duration="animationDuration"
    :can-cancel="false"
    :min-width="40"
    :min-height="58"
    has-modal-card>
    <header class="modal-card-head">
      <div class="modal-card-title"
        :class="{ 'is-flex-items-center' : showIcon}">
        <span v-if="showIcon"
          class="icon is-medium"
          :class="`has-text-${iconType}`"
          style="margin-right: 0.5rem">
          <i class="mdi mdi-36px"
            :class="`mdi-printer`" />
        </span>
        <span>Print Creditor Statement</span>
      </div>
    </header>
    <section class="modal-card-body">
      <creditor-list-statement-detail v-model="entity" />
    </section>
    <footer class="modal-card-foot"
      :style="`justify-content: flex-${buttonLocation}`">
      <button class="button is-success tooltip"
        @click="print()"
        data-tooltip="Print">Print</button>
      <button class="button tooltip"
        @click="close()"
        data-tooltip="Cancel">Cancel</button>
    </footer>
  </base-modal-ex>
</template>

<script>
import { BaseModalEx } from '@/components/BulmaModal'
import CreditorListStatementDetail from './CreditorListStatementDetail'
import storeMixin from './storeMixin'
import { Emailer } from '@/classes'
import { KeyValuePairModel } from '@/classes/viewmodels'
import PrintPreviewRoutes from '@/components/printpreview/route-types'
import StoreUtil from '@/store/utils'
import { RemarkTypes } from '@/enums'
import { DateTime } from 'luxon'

export default {
  name: 'CreditorListModal',
  components: {
    BaseModalEx,
    CreditorListStatementDetail
  },
  mixins: [storeMixin],
  props: {
    active: {
      type: Boolean,
      default: false
    },
    animationIn: {
      type: String,
      default: 'zoomIn'
    },
    animationOut: {
      type: String,
      default: 'zoomOut'
    },
    animationDuration: {
      type: [String, Number],
      default: 0.3
    },
    buttonLocation: {
      type: String,
      default: 'end'
    },
    showIcon: {
      type: Boolean,
      default: true
    },
    iconType: {
      type: String,
      default: 'warning'
    },
    iconName: {
      type: String,
      default: 'mdi-alert-decagram'
    }
  },
  data() {
    return {
      isActive: false,
      entity: {
        selectedFromDate: '',
        selectedToDate: '',
        selectedVendorName: '',
        selectedVendorId: ''
      }
    }
  },
  computed: {},
  watch: {
    active(value) {
      this.isActive = value
    }
  },
  mounted() {
    this.isActive = this.active || false
  },
  methods: {
    close() {
      this.$emit('update:active', false)
      this.$emit('close')
    },
    print() {
      const title = 'Creditor List'
      if (this.entity.selectedVendorId == '') {
        this.$notification.openNotificationWithType('warning', title, 'Please select Vendor')
        return false
      }
      if (this.entity.selectedFromDate == '') {
        this.$notification.openNotificationWithType('warning', title, 'Please select start date')
        return false
      }
      if (this.entity.selectedToDate == '') {
        this.$notification.openNotificationWithType('warning', title, 'Please select end date')
        return false
      }

      const params = {
        COMPANYNAME: this.$userInfo.companyName,
        REPORTTITLE: 'STATEMENT',
        FromDate: this.entity.selectedFromDate === '' ? '' : DateTime.fromISO(this.entity.selectedFromDate).toISODate(),
        ToDate: this.entity.selectedToDate === '' ? '' : DateTime.fromISO(this.entity.selectedToDate).toISODate(),
        VENDORID: this.entity.selectedVendorId,
        CompanyID: this.$userInfo.companyId
      }

      const keyValuePairs = KeyValuePairModel.convertToKeyValuePairs(params)
      const parameterId = this.$guid.newGuid()
      StoreUtil.setLocalStorage(parameterId, 'parameters', keyValuePairs)

      const emailer = new Emailer()
      emailer.assetId = this.entity.selectedVendorId
      emailer.remark = 'Creditor Statement'
      emailer.remarkTypeId = RemarkTypes.Remark
      emailer.subject = ' Creditor Statement for ' + this.entity.selectedVendorName
      emailer.reportName = 'CreditorStatement'
      StoreUtil.setLocalStorage(emailer.id, 'emailer', emailer)
      const routeData = this.$router.resolve({
        name: PrintPreviewRoutes.PrintPreview.name,
        params: { reportName: 'AT_CreditorStatement' },
        query: { parameterId: parameterId, emailerId: emailer.id, isNewTab: true }
      })
      window.open(routeData.href, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.is-flex-items-center {
  display: flex;
  align-items: center;
}
</style>
