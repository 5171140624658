import FqApi from '@/services/fq-api'
import ProtoBuf from 'protobufjs'

export default {
  async getEntity(creditorInvoiceId, hash) {
    const url = !hash ? `/creditorlist/${creditorInvoiceId}` : `/creditorlist/${creditorInvoiceId}?hash=${hash}`
    const result = await FqApi.get(url)
    return result.data
  },
  async getEntitySummaries(filter) {
    const serialised = this.serialiseCreditorListsFilter(filter)
    const url = `creditorlist/list/${serialised}`
    const result = await FqApi.get(url)
    return result.data
  },
  serialiseCreditorListsFilter(filter) {
    const entityProto = require('../../assets/proto/creditorlist/CreditorListFilter.proto')
    const filterMessage = entityProto.CreditorListFilter.CreditorListFilterModel
    const buffer = filterMessage.encode(filter).finish()
    const b64encoded = ProtoBuf.util.base64.encode(buffer, 0, buffer.length)
    return b64encoded
  },
  async getAccessRoutes() {
    const url = 'accesssetup/companyaccess'
    const result = await FqApi.get(url)
    return result.data
  }
}
