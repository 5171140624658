import { mapGetters, mapActions } from 'vuex'

const moduleName = 'creditorlist'

export default {
  computed: {
    ...mapGetters(moduleName, {
      returnRoute: 'returnRoute',
      snapshots: 'snapshots',
      baseSnapshot: 'baseSnapshot',
      currentSnapshot: 'currentSnapshot',
      snapshotDiff: 'snapshotDiff'
    }),
    ...mapGetters(['licenceExpired'])
  },
  methods: {
    ...mapActions(moduleName, ['getStoreItem', 'addStoreItem', 'saveSnapshot', 'clearSnapshots']),
    ...mapActions('purchaseorders', { setPurchaseOrderReturnRoute: 'setReturnRoute', setPurchaseOrderReadOnlyView: 'setReadOnlyView' }),
    ...mapActions('sundrycreditors', { setSundryCreditorReturnRoute: 'setReturnRoute', editSundryCreditorItem: 'editStoreItem', setSundryCreditorReadOnlyView: 'setReadOnlyView' }), // added setSundryCreditorReadOnlyView
    ...mapActions('returnparts', { setReturnPartsReturnRoute: 'setReturnRoute', setReturnPartsReadOnlyView: 'setReadOnlyView' }),
    ...mapActions('dealercreditentry', { setDealerCreditEntryReturnRoute: 'setReturnRoute', setDealerCreditEntryReadOnlyView: 'setReadOnlyView' }),
    ...mapActions('paymententries', { setPaymentEntryReadOnlyView: 'setReadOnlyView' }),
    ...mapActions('printpreview', { setPrintPreviewReturnRoute: 'setReturnRoute', addEmailer: 'addEmailer', removeEmailer: 'removeEmailer' }),
    ...mapActions('printpreview', { addReportParameters: 'addParameters', removeReportParameters: 'removeParameters' })
  }
}
