<template>
  <div>
    <article class="tile is-child box detail-page tile">
      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label has-text-left">Vendor</label>
        </div>
        <div class="field-body">
          <div class="field">
            <multiselect v-model="selectedVendorName"
              placeholder="Select a vendor"
              track-by="key"
              label="value"
              :options="vendors"
              :searchable="true"
              :show-labels="false"
              :allow-empty="true"
              @change="getVendorDropdownFilter()">
              <span class="has-text-danger"
                slot="noResult">Vendor not found.</span>
            </multiselect>
          </div>
        </div>
      </div>

      <div style="height: 265px;"
        class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label has-text-left">Invoice Date</label>
        </div>
        <div class="field-body">
          <div style="width: 580px;"
            class="columns">
            <div class="column">
              <v-date-picker v-model="selectedFromDate"
                :masks="formats"
                popover-visibility="focus"
                :attributes="attrs"
                :locale="$userInfo.locale"
                :model-config="{ timeAdjust: '00:00:00' }"
                :timezone="$filters.getIanaTimezone()">
                <template v-slot="{ inputValue, inputEvents }">
                  <div class="field">
                    <div class="control has-icons-left">
                      <input type="text"
                        class="input"
                        placeholder="Date From..."
                        :value="inputValue"
                        v-on="inputEvents">
                      <span class="icon is-small is-left">
                        <i class="mdi mdi-calendar mdi-18px" />
                      </span>
                    </div>
                  </div>
                </template>
              </v-date-picker>
            </div>

            <div class="column">
              <v-date-picker v-model="selectedToDate"
                :masks="formats"
                popover-visibility="focus"
                :attributes="attrs"
                :locale="$userInfo.locale"
                :model-config="{ timeAdjust: '23:59:59' }"
                :timezone="$filters.getIanaTimezone()">
                <template v-slot="{ inputValue, inputEvents }">
                  <div class="field">
                    <div class="control has-icons-left">
                      <input type="text"
                        class="input"
                        placeholder="Date To..."
                        :value="inputValue"
                        v-on="inputEvents">
                      <span class="icon is-small is-left">
                        <i class="mdi mdi-calendar mdi-18px" />
                      </span>
                    </div>
                  </div>
                </template>
              </v-date-picker>
            </div>
          </div>
        </div>
      </div>
    </article>
  </div>
</template>

<script>
import Guid from '@/components/Guid'
import Multiselect from 'vue-multiselect'
import _cloneDeep from 'lodash/cloneDeep'
import VendorService from '@/views/vendor/VendorService'
import { DateTimeFiltersMixin } from '@/components/mixins/filters'

export default {
  name: 'CreditorListStatementDetail',
  components: {
    Multiselect
  },
  mixins: [DateTimeFiltersMixin],
  props: {
    value: null,
    disableChange: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      innerValue: _cloneDeep(this.value),
      vendors: [],
      selectedVendorName: null,
      selectedVendorId: null,
      selectedFromDate: null,
      selectedToDate: null,
      formats: {
        title: 'MMMM YYYY',
        weekdays: 'W',
        navMonths: 'MMM',
        input: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        data: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        dayPopover: 'L'
      },
      attrs: [
        {
          key: 'today',
          highlight: {
            backgroundColor: '#ff8080'
          },
          popover: {
            label: 'Today'
          },
          dates: new Date()
        }
      ],
      assetFilter: {
        assetId: Guid.empty(),
        name: '',
        pageIndex: 1,
        pageSize: 50
      },
      isVendorLoading: false
    }
  },
  watch: {
    selectedFromDate: function(newVal, oldVal) {
      if (newVal) {
        this.value.selectedFromDate = newVal.toISOString().split('.')[0] + 'Z'
      } else {
        this.value.selectedFromDate = ''
      }
    },
    selectedToDate: function(newVal, oldVal) {
      if (newVal) {
        this.value.selectedToDate = newVal.toISOString().split('.')[0] + 'Z'
      } else {
        this.value.selectedToDate = ''
      }
    },
    'selectedVendorName.key': function(newVal, oldVal) {
      if (newVal) {
        this.value.selectedVendorId = newVal
      }
    },
    'selectedVendorName.value': function(newVal, oldVal) {
      if (newVal) {
        this.value.selectedVendorName = newVal
      }
    }
  },
  async created() {
    this.getVendorDropdown()
  },
  methods: {
    async getVendorDropdown() {
      this.isVendorLoading = true
      this.vendors = await VendorService.getVendorDropdownProto(this.assetFilter)
      this.isVendorLoading = false
    },
    getVendorDropdownFilter() {
      this.innerValue.selectedVendorId = this.selectedVendorName.key
      this.innerValue.selectedVendorName = this.selectedVendorName.value
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/style/mixins/inputs/placeholder';

input.is-uppercase {
  text-transform: uppercase;
  @include reset-placeholder-text-transform;
}
</style>
