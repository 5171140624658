<template>
  <div v-if="$userInfo">
    <app-detail-header :show-toggle="true"
      :show-delete="false"
      :show-save="false"
      :show-cancel="false"
      :show-new="false"
      @appDetailHeaderButtonClicked="onHeaderButtonClicked" />
    <div class="columns is-gapless">
      <div class="column is-narrow">
        <creditor-list-side-menu v-model="filter"
          @filter="filterList"
          @reset="resetList" />
      </div>
      <div class="column">
        <div class="tile is-parent">
          <article class="tile is-child box detail-page-tile">
            <subtitle-totals :labels="['Total Balance', 'Total Inv. Incl. GST']"
              :totals="[sumBalance, sumTotalIncGst]"
              :shows="[true, true]" />
            <bulma-table class="table is-bordered is-striped is-narrow is-fullwidth"
              :columns="columns"
              :page-index="filter.pageIndex"
              :page-size="filter.pageSize"
              :total-rows="totalRows"
              :is-loading="isTableLoading"
              :sort-column="filter.sortColumn"
              :sort-order="filter.sortOrder"
              @pageChanged="onPageChange"
              @sort="sort">
              <tr v-for="(entity, index) in entitySummaries"
                :key="entity.creditorInvoiceId"
                :class="{ 'is-selected' : selectedRow === index }"
                @click="highlightSelected(index, $event)">
                <td><a @click.prevent="onVeiwInvoice(entity.creditorInvoiceId, entity.invoiceType, entity.invoiceRef, entity.purchaseOrderID)">{{ entity.invoiceRef }}</a></td>
                <td>{{ entity.creditorName }}</td>
                <td class="has-text-left">{{ $filters.formatDateTimezone(entity.invoiceDate, $userInfo.locale) }}</td>
                <td class="has-text-left">{{ $filters.formatDateTimezone(entity.due, $userInfo.locale) }}</td>
                <td class="has-text-right">{{ entity.balance | formatNumber($userInfo.locale) }}</td>
                <td class="has-text-right">{{ entity.totalAmount | formatNumber($userInfo.locale) }}</td>
                <td class="has-text-right">{{ entity.gstAmount | formatNumber($userInfo.locale) }}</td>
                <td class="has-text-right">{{ entity.amount | formatNumber($userInfo.locale) }}</td>
                <td class="has-text-left">{{ entity.purchaseOrderNo }}</td>
                <td class="has-text-left">
                  <span v-if="entity.quoteDeleted"
                    style="padding-right: 3px"
                    class="icon has-text-danger tooltip is-tooltip-danger"
                    data-tooltip="Quote has been deleted.">
                    <i class="mdi mdi-alert mdi-18px" />
                  </span>
                  {{ entity.quoteNo }}
                </td>
                <td class="has-text-centered">{{ entity.abn | formatABN }}</td>
                <td class="has-text-centered">
                  <span class="icon"
                    :class="[ entity.exported ? 'has-text-success' : 'has-text-danger' ]">
                    <i class="mdi mdi-24px"
                      :class="[ entity.exported ? 'mdi-check' : '' ]" />
                  </span>
                </td>
                <td class="has-text-centered">
                  <a class="button is-primary is-small is-inverted tooltip"
                    @click="onVeiwInvoice(entity.creditorInvoiceId, entity.invoiceType, entity.invoiceRef, entity.purchaseOrderID)"
                    data-tooltip="Click to view">
                    <span class="icon is-medium">
                      <i class="mdi mdi-file-document mdi-24px" />
                    </span>
                  </a>
                </td>
              </tr>
              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <span icon="icon is-large">
                      <i class="mdi mdi-48px mdi-emoticon-sad" />
                    </span>
                    <p>Nothing</p>
                  </div>
                </section>
              </template>
            </bulma-table>
          </article>
        </div>
      </div>
    </div>
    <payment-entry-advance-payment-modal v-if="isAdvancePaymentModalActive"
      :active.sync="isAdvancePaymentModalActive"
      v-model="entitySummaries"
      :show-amount="false"
      @close="closeAdvancePaymentModal" />
    <vue-fab position="right-bottom"
      :z-index="30"
      :actions="fabActions"
      main-tooltip="Creditor List Actions"
      @showCreditorStatement="showCreditorStatement()" />
    <creditor-list-model v-if="isLoadCreditorStatementModalActive"
      :active.sync="isLoadCreditorStatementModalActive"
      :show-icon="true"
      @close="closeModal()">
      <p slot="text-title">Print Creditor Statements</p>
    </creditor-list-model>
  </div>
</template>

<script>
import AppDetailHeader from '@/components/AppDetailHeader'
import CreditorListSideMenu from './CreditorListSideMenu'
import CreditorListService from './CreditorListService'
import { AppHeaderButtonTypes } from '@/enums'
import store from '@/store'
import BulmaTable from '@/components/BulmaTable'
import { DateTimeFiltersMixin, NumberFiltersMixin } from '@/components/mixins/filters'
import { Columns } from './columns'
import Guid from '@/components/Guid'
import StoreMixin from './storeMixin'
import { DateTime } from 'luxon'
import { Emailer } from '@/classes'
import { KeyValuePairModel } from '@/classes/viewmodels'
import PurchaseOrderRoutes from '@/views/purchaseorder/route-types'
import PrintPreviewRoutes from '@/components/printpreview/route-types'
import CreditorListModal from './CreditorListModal'
import { VueFab } from '@/components/VueFab'
import SubtitleTotals from '@/components/SubtitleTotals'
import ReturnPartsRoutes from '@/views/returnparts/route-types'
import DealerCreditEntryRoutes from '@/views/dealercreditentry/route-types'
import PaymentEntryAdvancePaymentModal from '@/views/paymententry/PaymentEntryAdvancePaymentModal'

export default {
  name: 'CreditorListListView',
  components: {
    AppDetailHeader,
    [CreditorListSideMenu.name]: CreditorListSideMenu,
    [CreditorListModal.name]: CreditorListModal,
    BulmaTable,
    VueFab,
    SubtitleTotals,
    PaymentEntryAdvancePaymentModal
  },
  filters: {
    formatABN(value) {
      const abn = value || ''
      return `${abn.slice(0, 2)} ${abn.slice(2, 5)} ${abn.slice(5, 8)} ${abn.slice(8, 11)}`
    }
  },
  mixins: [DateTimeFiltersMixin, StoreMixin, NumberFiltersMixin],
  data() {
    return {
      entitySummaries: [],
      selectedEntity: null,
      totalRows: 0,
      sumTotalIncGst: 0.0,
      sumBalance: 0.0,
      filter: {
        invoiceNo: '',
        creditorName: '',
        quoteNo: '',
        poNo: '',
        startDate: '',
        endDate: '',
        filterType: 1,
        sortColumn: Columns[0].name,
        sortOrder: Columns[0].defaultOrder,
        pageIndex: 1,
        pageSize: 15,
        exported: 'B'
      },
      toggle: true,
      selectedRow: null,
      isTableLoading: false,
      fabActions: [],
      isLoadCreditorStatementModalActive: false,
      isAdvancePaymentModalActive: false
    }
  },
  computed: {
    filterKey() {
      if (this.$userInfo) {
        return `${this.$userInfo.sessionId}|${this.$route.meta.fkey}`
      } else {
        return ''
      }
    },
    columns() {
      return Columns
    }
  },
  created() {
    this.getEntitySummaries()
    this.getAccessRoutes()
    this.setupFabButtons()
  },
  mounted() {},
  methods: {
    async showCreditorStatement() {
      this.isLoadCreditorStatementModalActive = true
    },
    setupFabButtons() {
      this.fabActions.splice(this.fabActions.length, 1, {
        name: 'showCreditorStatement',
        icon: 'printer',
        color: '#a8a145',
        tooltip: 'Print statement'
      })
    },
    closeModal() {
      this.isLoadCreditorStatementModalActive = false
    },
    highlightSelected(index, event) {
      this.selectedRow = index
    },
    onVeiwInvoice(creditorInvoiceId, invoiceType, invoiceRef, purchaseOrderID) {
      if (creditorInvoiceId !== Guid.empty()) {
        this.gotoV1(creditorInvoiceId, invoiceType, invoiceRef, purchaseOrderID)
      }
    },
    onHeaderButtonClicked(action) {
      switch (action) {
        case AppHeaderButtonTypes.AddNew:
          break
        case AppHeaderButtonTypes.Print:
          this.print()
          break
        case AppHeaderButtonTypes.Delete:
          break
        case AppHeaderButtonTypes.Save:
          break
        case AppHeaderButtonTypes.Cancel:
          break
      }
    },
    filterList() {
      this.filter.pageIndex = 1
      this.persistFilter()
      this.getEntitySummaries()
    },
    sort(name, order) {
      this.filter.sortColumn = name
      this.filter.sortOrder = order
      this.persistFilter()
      this.getEntitySummaries()
    },
    resetList() {
      this.filter.invoiceNo = ''
      this.filter.creditorName = ''
      this.filter.quoteNo = ''
      this.filter.poNo = ''
      this.filter.startDate = ''
      this.filter.endDate = ''
      this.filter.filterType = 1
      this.filter.sortColumn = Columns[0].name
      this.filter.sortOrder = Columns[0].defaultOrder
      this.filter.pageIndex = 1
      this.filter.pageSize = 15
      this.filter.exported= 'B'
      this.persistFilter()
      this.getEntitySummaries()
    },
    persistFilter() {
      sessionStorage.setItem(this.filterKey, JSON.stringify(this.filter))
    },
    retrieveFilter() {
      const filter = JSON.parse(sessionStorage.getItem(this.filterKey))
      if (filter) {
        this.filter = filter
      }
    },
    onPageChange(pageIndex) {
      this.filter.pageIndex = pageIndex
      this.persistFilter()
      this.getEntitySummaries()
    },
    async getEntitySummaries() {
      this.isTableLoading = true
      this.retrieveFilter()
      this.entitySummaries = await CreditorListService.getEntitySummaries(this.filter)
      this.totalRows = this.entitySummaries.length > 0 ? this.entitySummaries[0].totalRows : 0
      if (this.entitySummaries.length > 0) {
        this.sumBalance = this.entitySummaries[0].sumBalance
        this.sumTotalIncGst = this.entitySummaries[0].sumTotalIncGst
      } else {
        this.sumBalance = 0
        this.sumTotalIncGst = 0
      }
      this.isTableLoading = false
    },
    async getAccessRoutes() {
      this.accessRoutes = await CreditorListService.getAccessRoutes()
    },
    async gotoV1(creditorInvoiceId, invoiceType, invoiceRef, purchaseOrderID) {
      let paymentEntryRoute = this.accessRoutes.records.filter(obj => {
        return obj.code === 'A048'
      })

      let returnPartRoute = this.accessRoutes.records.filter(obj => {
        return obj.code === 'A072'
      })

      let purchaseOrderRoute = this.accessRoutes.records.filter(obj => {
        return obj.code === 'A106'
      })

      let dealerCreditEntryRoute = this.accessRoutes.records.filter(obj => {
        return obj.code === 'A060'
      })

      switch (true) {
        case invoiceType === 1:
          // await store.dispatch('sundrycreditors/editStoreItem', creditorInvoiceId)
          this.setSundryCreditorReadOnlyView(true)
          this.setSundryCreditorReturnRoute(this.$route)
          this.editSundryCreditorItem(creditorInvoiceId)
          break

        // Advance Payment V1
        case invoiceType === 3 && paymentEntryRoute[0].version.toUpperCase() !== '2':
          let cID = Guid.createShortGuid(creditorInvoiceId)
          window.open(
            `${process.env.VUE_APP_ROOT_URI}/Modules/PaymentEntryView/PaymentEntry.aspx?RPId=${cID}&RedirectUrl=${process.env.VUE_APP_BASE_URI}/creditorlist`,
            '_self'
          )
          break

        // Advance Payment V2
        case invoiceType === 3 && paymentEntryRoute[0].version.toUpperCase() === '2':
          this.setPaymentEntryReadOnlyView(true)
          await store.dispatch('paymententries/editStoreItemByInvoiceId', creditorInvoiceId)
          this.isAdvancePaymentModalActive = true
          break

        // Return Parts V1
        case invoiceType === 4 && returnPartRoute[0].version.toUpperCase() !== '2':
          window.open(
            `${process.env.VUE_APP_ROOT_URI}/Modules/ReturnPartsView/ReturnParts.aspx?RPId=${creditorInvoiceId}&RedirectUrl=${process.env.VUE_APP_BASE_URI}/creditorlist`,
            '_self'
          )
          break

        // Return Parts V2
        case invoiceType === 4 && returnPartRoute[0].version.toUpperCase() === '2':
          this.setReturnPartsReadOnlyView(true)
          this.setReturnPartsReturnRoute(this.$route)
          this.$router.push({
            name: ReturnPartsRoutes.ReturnPartsDetail.name,
            params: { creditReturnMainId: creditorInvoiceId }
          })
          break

        //Purchase order V1
        case invoiceType === 5 && purchaseOrderRoute[0].version.toUpperCase() !== '2':
          let poID = Guid.createShortGuid(purchaseOrderID)
          window.open(
            `${process.env.VUE_APP_ROOT_URI}/Modules/PurchaseOrderView/PurchaseOrder.aspx?RPId=${creditorInvoiceId}&poId=${poID}&RedirectUrl=${process.env.VUE_APP_BASE_URI}/creditorlist`,
            '_self'
          )
          break

        //Purchase order V2
        case invoiceType === 5 && purchaseOrderRoute[0].version.toUpperCase() === '2':
          this.setPurchaseOrderReadOnlyView(true)
          this.setPurchaseOrderReturnRoute(this.$route)
          this.$router.push({
            name: PurchaseOrderRoutes.PurchaseOrderOrderReceiptDetail.name,
            params: { purchaseOrderId: purchaseOrderID, creditorInvoiceId: creditorInvoiceId },
            route: 'orderreceipt'
          })
          break

        case invoiceType === 6 && dealerCreditEntryRoute[0].version.toUpperCase() !== '2':
          const str = invoiceRef.substring(0, 2)

          // Return Parts
          if (str == 'AJ') {
            window.open(
              `${process.env.VUE_APP_ROOT_URI}/Modules/ReturnPartsView/ReturnParts.aspx?RPId=${creditorInvoiceId}&RedirectUrl=${process.env.VUE_APP_BASE_URI}/creditorlist`,
              '_self'
            )
            break
          } // Dealer Credit Entry
          else {
            window.open(
              `${process.env.VUE_APP_ROOT_URI}/Modules/CreditorView/DealerCreditEntry.aspx?RPId=${creditorInvoiceId}&RedirectUrl=${process.env.VUE_APP_BASE_URI}/creditorlist`,
              '_self'
            )
            break
          }

        //Dealer Credit Entry V2
        case invoiceType === 6 && dealerCreditEntryRoute[0].version.toUpperCase() === '2':
          this.setDealerCreditEntryReadOnlyView(true)
          this.setDealerCreditEntryReturnRoute(this.$route)
          this.$router.push({
            name: DealerCreditEntryRoutes.DealerCreditEntryView.name,
            params: { creditorInvoiceId: creditorInvoiceId }
          })
          break

        //Receipt Entry
        case invoiceType === 7:
          window.open(`${process.env.VUE_APP_ROOT_URI}/Modules/ReceiptEntryView/FactorDetails.aspx?RPId=${creditorInvoiceId}`, '_self')
          break
      }
    },
    print() {
      let dateFrom = this.filter.startDate
      let dateTo = this.filter.endDate

      let params = {
        COMPANYNAME: this.$userInfo.companyName,
        REPORTTITLE: 'Creditor List',
        FromDate: dateFrom,
        ToDate: dateTo,
        Creditor: this.filter.creditorName,
        PONo: this.filter.poNo,
        Type: this.filter.filterType,
        ReportType: '1',
        QuoteNo: this.filter.quoteNo,
        InvoiceNo: this.filter.invoiceNo,
        CompanyID: this.$userInfo.companyId,
        SortBy: this.filter.sortColumn,
        timezoneoffset: this.$filters.getTimezoneOffset(),
        SortDirection: this.filter.sortOrder,
        Exported: this.filter.exported
      }
      console.log(DateTime.fromISO(this.filter.startDate).toLocaleString())
      const emailer = new Emailer()
      emailer.subject = 'Creditor List'
      emailer.reportName = 'CreditorList'
      this.addEmailer(emailer)
      const keyValuePairs = KeyValuePairModel.convertToKeyValuePairs(params)
      this.addReportParameters(keyValuePairs)

      this.$router.push({
        name: PrintPreviewRoutes.PrintPreview.name,
        params: { reportName: this.$route.meta.report },
        query: { parameterId: this.$guid.newGuid(), emailerId: emailer.id }
      })
    },
    closeAdvancePaymentModal() {
      this.isAdvancePaymentModalActive = false
    }
  }
}
</script>